.App {
  padding: 10px 5% 5%;
  min-height: 80vh;
}

.App-loading {
  text-align: center;
  margin: auto;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  height: 100vh;
}

.App-logo {
  margin: auto;
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
